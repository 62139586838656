import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaLongArrowAltRight } from "react-icons/fa";

const TeamSection = ({
	image,
	name,
	text,
	role,
	bgColour,
	imgAlt,
	order,
	url,
}) => {
	return (
		<Row className="align-items-center py-xl-6 py-4">
			<Col className="mb-6 mb-xl-0" md={{ span: 6, order: order }}>
				<Row
					className={` ${
						order ? "justify-content-end" : "justify-content-start"
					}`}
				>
					<Col lg={10} className="ps-lg-0 ps-md-4" xl={8}>
						{" "}
						<div
							style={{ background: "none" }}
							className={` w-100 h-100 team-image  position-relative`}
						>
							<div className="h-20"></div>
							<div
								style={{ zIndex: -1 }}
								className={`position-absolute treatment-card-image top-20 w-lg-100 h-100 ${bgColour}`}
							></div>
							<div className={`position-relative team-image-container `}>
								<div
									style={{ zIndex: 0 }}
									className={` position-absolute top--20`}
								>
									<GatsbyImage
										style={{ width: "100%" }}
										className="treatment-card-image"
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Col>
			<Col className=" mt-md-5  px-4 px-lg-0" md={6}>
				<p className="text-text-secondary jost-bold fs-6">{role}</p>
				<h2 className=" pb-4 display-4 ">{name}</h2>
				<p className="pe-lg-8">{text}</p>
				<Link
					to={url}
					className="fs-5 d-flex align-items-center justify-content-start gold-link"
				>
					READ BIO <FaLongArrowAltRight className="ms-2 fs-6" />
				</Link>
			</Col>
		</Row>
	);
};

export default TeamSection;
