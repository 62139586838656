import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import TeamSection from "../components/team-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import uuid4 from "uuid4";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainTeam: allWpTeamMember(
        sort: { fields: teamMemberFields___order, order: ASC }
        filter: { teamMemberFields: { hasBio: { eq: true } } }
      ) {
        nodes {
          slug
          teamMemberFields {
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            description
            firstName
            gdcNo
            hasBio
            info
            lastName
            role
            order
          }
        }
      }
      secondaryTeam: allWpTeamMember(
        sort: { fields: teamMemberFields___order, order: ASC }
        filter: { teamMemberFields: { hasBio: { ne: true } } }
      ) {
        nodes {
          slug
          teamMemberFields {
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            firstName
            lastName
            role
            order
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "about" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        aboutPageFieldGroups {
          introSection {
            heading
            subHeading
          }
        }
      }
    }
  `);

  const {
    pageData: {
      seoFieldGroups,
      aboutPageFieldGroups: { introSection },
    },
  } = data;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "About",
        item: {
          url: `${siteUrl}/about`,
          id: `${siteUrl}/about`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/about`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section className="pb-4">
          <Container>
            <Row>
              <Col className="text-center mb-xl-10 my-5">
                <p className="text-text-secondary jost-bold fs-6">
                  {introSection?.subHeading || "OUR TEAM"}
                </p>
                <h1 className="display-4 ">
                  {introSection?.heading || "Meet our dental experts"}
                </h1>
              </Col>
            </Row>
            {data.mainTeam.nodes.map(function (member, index) {
              if (index % 2 !== 0) {
                return (
                  <TeamSection
                    key={uuid4()}
                    text={member.teamMemberFields.description}
                    role={member.teamMemberFields.role}
                    name={
                      <span>
                        {member.teamMemberFields.firstName}{" "}
                        {member.teamMemberFields.lastName}
                      </span>
                    }
                    bgColour="bg-secondary"
                    image={
                      member.teamMemberFields.image.node.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    imgAlt={member.teamMemberFields.image.node.altText}
                    url={`/biography/${member.slug}`}
                  />
                );
              } else {
                return (
                  <TeamSection
                    key={uuid4()}
                    text={member.teamMemberFields.description}
                    role={member.teamMemberFields.role}
                    name={
                      <span>
                        {member.teamMemberFields.firstName}{" "}
                        {member.teamMemberFields.lastName}
                      </span>
                    }
                    bgColour="bg-primary"
                    image={
                      member.teamMemberFields.image.node.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    imgAlt={member.teamMemberFields.image.node.altText}
                    url={`/biography/${member.slug}`}
                    order="last"
                  />
                );
              }
            })}
          </Container>
        </section>
        {/* <section className="mt-lg-7 mt-5">
					<Container>
						{" "}
						<Row className="h-100 justify-content-center">
							{data.secondaryTeam.nodes.map((member) => (
								<SecondaryTeamCard
									image={
										member.teamMemberFields.image.node.localFile.childImageSharp
											.gatsbyImageData
									}
									imgAlt={member.teamMemberFields.image.node.altText}
									role={member.teamMemberFields.role}
									name={
										<span>
											{member.teamMemberFields.firstName}{" "}
											{member.teamMemberFields.lastName}
										</span>
									}
								/>
							))}
							;
						</Row>
					</Container>
				</section> */}
      </Layout>
    </>
  );
};

export default AboutPage;
